<template>
    <parent-layout title="District Instances">
        <client-instance-list :client-id="getIlluminateClientId" />
    </parent-layout>
</template>

<script>
import ParentLayout from '@/components/layout/ParentLayout'
import ClientInstanceList from '@/views/Imt/Clients/ClientInstanceList'
import { mapGetters } from 'vuex'

export default {
    components: { ParentLayout, ClientInstanceList },
    computed: {
        ...mapGetters({ getIlluminateClientId: 'getIlluminateClientId' }),
    },
}
</script>